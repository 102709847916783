import 'antd/dist/antd.less'
import '../styles/amplify.less'
import '../styles/global.less'

import { DashboardOutlined } from '@ant-design/icons'
import { Route } from '@ant-design/pro-layout/lib/typings'
import { ApolloProvider } from '@apollo/client'
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components'
import {
  AmplifyAuthenticator,
  AmplifyForgotPassword,
  AmplifySignIn,
} from '@aws-amplify/ui-react'
import { ConfigProvider } from 'antd'
import locale from 'antd/lib/locale-provider/ja_JP'
import Amplify, { I18n } from 'aws-amplify'
import { AppProps } from 'next/dist/next-server/lib/router/router'
import Router from 'next/router'
import React, { useEffect, useState } from 'react'

import { useApollo } from '../apollo/apolloClient'
import { BasicLayout } from '../components/layouts'
import { vocabularies } from '../config/i18n/amplify'
import ErrorTracker from '../utils/ErrorTracker'

Amplify.configure({
  Auth: {
    region: process.env.NEXT_PUBLIC_AWS_REGION,
    userPoolId: process.env.NEXT_PUBLIC_USER_POOL_ID,
    userPoolWebClientId: process.env.NEXT_PUBLIC_USER_POOL_WEB_CLIENT_ID,
  },
})

I18n.putVocabulariesForLanguage('ja', vocabularies)
I18n.setLanguage('ja')

const route: Route = {
  routes: [
    {
      path: '/reports/usage',
      name: 'チケット使用集計期間一覧',
      icon: <DashboardOutlined />,
      routes: [
        {
          path: '/reports/usage/detail',
          name: 'チケット使用明細',
          hideInMenu: true,
        },
      ],
    },
  ],
}

const useLoadingState = () => {
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    Router.events.on('routeChangeStart', () => setLoading(true))
    Router.events.on('routeChangeComplete', () => setLoading(false))
    Router.events.on('routeChangeError', () => setLoading(false))
  }, [])

  return { loading }
}

const useAuthState = () => {
  const [authState, setAuthState] = useState<AuthState>()
  const [user, setUser] = useState<{ email: string }>()
  useEffect(
    () =>
      onAuthUIStateChange((nextAuthState, authData) => {
        setAuthState(nextAuthState)
        setUser((authData as any)?.attributes)
      }),
    []
  )

  return { authState, user }
}

const MyApp = ({ Component, pageProps }: AppProps) => {
  const apolloClient = useApollo()
  const { authState, user } = useAuthState()
  const { loading } = useLoadingState()

  useEffect(() => {
    if (authState) {
      ErrorTracker.setUser(user || null)
    }
  }, [authState, user])

  return authState === AuthState.SignedIn && user ? (
    <ApolloProvider client={apolloClient}>
      <ConfigProvider locale={locale}>
        <BasicLayout user={user} route={route} loading={loading}>
          <Component {...pageProps} />
        </BasicLayout>
      </ConfigProvider>
    </ApolloProvider>
  ) : (
    <AmplifyAuthenticator>
      <AmplifySignIn slot="sign-in" usernameAlias="email" hideSignUp />
      <AmplifyForgotPassword slot="forgot-password" usernameAlias="email" />
    </AmplifyAuthenticator>
  )
}

export default MyApp
